import { useLazyQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';

import { RECOMMENDED_PRODUCTS } from '@queries/user';
import { useProductPlansLazyQuery } from './useProductPlans';
import { formatPrice } from '@services/format';
import { IHealthRecommendedProductsQueryResponse } from '../types/query-response';

const usePersonalizePlan = () => {
  const [getRecommendedProducts, { data: dataProducts, loading: loadingProducts, error: errorProducts }] = useLazyQuery<
    IHealthRecommendedProductsQueryResponse
  >(RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const personalisedProductRecommendation = dataProducts?.health_recommendedProducts.find(product =>
    product?.id.startsWith('sn')
  );

  const [getPlans, { data: dataPlans, loading: loadingPlans, error: errorPlans }] = useProductPlansLazyQuery();

  useEffect(() => {
    personalisedProductRecommendation &&
      getPlans({
        variables: {
          productId: 'personalised',
        },
      });
  }, [personalisedProductRecommendation]);

  const plans = dataPlans?.product_plans?.filter(item =>
    item?.sku?.includes(`${personalisedProductRecommendation?.id}-`)
  );

  const personalisedProductPrice =
    (plans && plans.length > 0 && formatPrice(plans[0].rrpPrice, plans[0].currency)) || '';

  const isSubscription = (plans && plans.length > 1) || false;

  return {
    loadingProducts,
    loadingPlans,
    errorProducts,
    errorPlans,
    isSubscription,
    plans,
    personalisedProductPrice,
    personalisedProductRecommendation,
    getRecommendedProducts,
  };
};

export default usePersonalizePlan;
