import React from 'react';
import Image from '@src/components/image/image';

interface IProps {
  filenameDisk: string;
  backgroundColor: string;
  height?: number;
}

import styles from './product.module.scss';

const ProductImage = ({ filenameDisk, backgroundColor, height = 186 }: IProps) => {
  return (
    <div className={styles.productImage} style={{ backgroundColor, height }}>
      <Image filename={filenameDisk} width={300} />
    </div>
  );
};

export default ProductImage;
