import React from 'react';

import ProductLink from './product-link';
import { IProduct } from '@features/shop/interfaces/shop';
import { useShopText } from '@features/shop/hooks/useShopText';

import styles from './recommended-product.module.scss';

interface IProps {
  product: IProduct;
  price: string;
  loading: boolean;
  offerPrice?: string;
  personalized?: boolean;
  onClick: () => void;
}

const FooterWithLink = ({ product, onClick, loading, personalized, price, offerPrice }: IProps) => {
  const shopText = useShopText();
  const renderPrice = () => {
    if (loading)
      return (
        <span className={styles.loaderWrap}>
          <span className={styles.loader} />
        </span>
      );
    if (personalized && !price) return <span>Take health quiz</span>;
    if (!price) return <span className={styles.outOfStock}>{shopText.back_in_stock_soon}</span>;

    if (offerPrice) {
      return (
        <>
          <span>Shop now</span>
          <span className={styles.offerPrice}>{price}</span> {offerPrice}
        </>
      );
    }
    return (
      <>
        <span>Shop now</span>
        <span>{price}</span>
      </>
    );
  };
  return (
    <div className={styles.footer}>
      <ProductLink footer link={product.route} onClick={onClick}>
        {renderPrice()}
      </ProductLink>
    </div>
  );
};

export default FooterWithLink;
