import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GlobalContext } from '@store/global-state';
import { RECOMMENDED_PRODUCTS } from '@queries/user';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { useProductPlansLazyQuery } from '@hooks/useProductPlans';
import { formatPrice } from '@services/format';
import { IHealthRecommendedProductsQueryResponse } from '../../../types/query-response';

const useGetEssentialProductPlan = () => {
  const { setErrorModalMessage, isLoggedIn } = useContext(GlobalContext);

  const [getRecommendedProducts, { data: dataProducts, loading: loadingProducts }] = useLazyQuery<
    IHealthRecommendedProductsQueryResponse
  >(RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'network-only',
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.getRecommendedProducts);
    },
  });

  const essentialOneRecommendation = dataProducts?.health_recommendedProducts?.find((product: any) =>
    product.id.startsWith('essential-one')
  );

  const [getPlans, { data: dataPlans, loading: loadingPlans }] = useProductPlansLazyQuery({
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.getProductPlans);
    },
  });

  useEffect(() => {
    if (isLoggedIn) getRecommendedProducts();
  }, [isLoggedIn]);

  useEffect(() => {
    essentialOneRecommendation &&
      getPlans({
        variables: {
          productId: 'essential-one',
        },
      });
  }, [essentialOneRecommendation]);

  let plans = essentialOneRecommendation
    ? dataPlans?.product_plans?.filter((item: any) =>
        item?.sku?.replace('-monthly-30', '')?.includes(essentialOneRecommendation?.id)
      )
    : null;

  // Make sure we always use the first element from the array
  plans = plans && [plans[0]];

  const essentialOnePrice = plans ? formatPrice(plans[0]?.rrpPrice, plans[0]?.currency) : '';

  const hasSubscriptionPlan = dataPlans && dataPlans?.product_plans.some(plan => plan.deliveryFrequency);

  return {
    price: essentialOnePrice,
    plans,
    loadingPlans,
    loadingProducts,
    hasSubscriptionPlan: !!hasSubscriptionPlan,
  };
};

export default useGetEssentialProductPlan;
