import React from 'react';

import { useShopText } from '@features/shop/hooks/useShopText';
import { IProduct } from '@features/shop/interfaces/shop';

interface IProps {
  product: IProduct;
  className?: string;
}

interface IBadgeWrapProps {
  className?: string;
  children: React.ReactNode;
}

import styles from './product.module.scss';

const ProductBadgeWrap = ({ className, children }: IBadgeWrapProps) => (
  <div className={`${styles.badgeWrap} ${className}`}>{children}</div>
);

const ProductBadge = ({ product, className = '' }: IProps) => {
  const shopText = useShopText();
  if (product.best_seller)
    return (
      <ProductBadgeWrap className={className}>
        <span className={styles.badge}>{shopText.best_seller}</span>
      </ProductBadgeWrap>
    );
  if (product.selling_fast)
    return (
      <ProductBadgeWrap className={className}>
        <span className={`${styles.badge} ${styles.fast}`}>{shopText.selling_fast}</span>
      </ProductBadgeWrap>
    );
  if (product.low_stock)
    return (
      <ProductBadgeWrap className={className}>
        <span className={`${styles.badge} ${styles.low}`}>{shopText.low_in_stock}</span>
      </ProductBadgeWrap>
    );
  if (product.digital_product) {
    return (
      <ProductBadgeWrap className={className}>
        <span className={`${styles.badge}`}>{shopText.digital_product}</span>
      </ProductBadgeWrap>
    );
  }
  return null;
};

export default ProductBadge;
