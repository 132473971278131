import React, { useEffect } from 'react';

import usePersonalizePlan from '@hooks/usePersonalizePlan';
import { getCookie } from '@services/cookies';
import { COOKIES } from '@constants/cookies';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import useGlobalError from '@hooks/useGlobalError';

const useGetPersonalizedProductPrice = (dontShowGlobalError?: boolean) => {
  const {
    loadingProducts,
    loadingPlans,
    errorProducts,
    errorPlans,
    plans,
    personalisedProductPrice,
    getRecommendedProducts,
    isSubscription,
  } = usePersonalizePlan();

  useEffect(() => {
    if (getCookie(COOKIES.token)) {
      getRecommendedProducts();
    }
  }, []);

  if (!dontShowGlobalError) {
    useGlobalError(!!errorProducts, ERROR_MESSAGES.getRecommendedProducts);
    useGlobalError(!!errorPlans, ERROR_MESSAGES.getProductPlans);
  }

  return {
    personalisedProductPrice,
    loadingPlans,
    loadingProducts,
    isSubscription,
    plans,
  };
};

export default useGetPersonalizedProductPrice;
