import React from 'react';

import { IProduct } from '@features/shop/interfaces/shop';
import RecommendedProductUI from '../recommended-product-ui/recommended-product-ui';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import {
  BUNDLE_ITEMS_SKUS,
  ESSENTIAL_PRODUCT_SKU,
  PERSONALIZED_PRODUCT_SKU,
} from '@features/shop/constants/products';
import PersonalizedProduct from './personalized-product';
import EssentialProduct from './essential-product';
import { IBasketItem } from '../../../types/basket';

export interface IHomepageRecommendedProductTrack {
  position: number;
  product: string;
}

interface IProps {
  product: IProduct;
  index: number;
  isAddToBasketView?: boolean;
  height?: number;
  onClick: (data: IHomepageRecommendedProductTrack) => void;
  onAddToBasketCallback?: (currency: string, lastModifiedItem?: IBasketItem, sku?: string) => void;
}

const RecommendedProduct = ({
  product,
  index,
  isAddToBasketView,
  height,
  onClick,
  onAddToBasketCallback,
}: IProps) => {
  const trackingData = {
    position: index + 1,
    product: product.identifier,
  };

  if (product.identifier === PERSONALIZED_PRODUCT_SKU) {
    return (
      <PersonalizedProduct
        product={product}
        isAddToBasketView={isAddToBasketView}
        height={height}
        onClick={() => onClick(trackingData)}
        onAddToBasketCallback={onAddToBasketCallback}
      />
    );
  }

  if (product.identifier === ESSENTIAL_PRODUCT_SKU) {
    return (
      <EssentialProduct
        product={product}
        isAddToBasketView={isAddToBasketView}
        height={height}
        onClick={() => onClick(trackingData)}
        onAddToBasketCallback={onAddToBasketCallback}
      />
    );
  }

  if (BUNDLE_ITEMS_SKUS.includes(product.identifier)) {
    return null;
  }

  const { dataPlans, loading, price, offerPrice, hasSubscriptionPlan } = useGetProductPlansData(
    product.identifier
  );

  const productPlans = dataPlans?.product_plans;

  return (
    <RecommendedProductUI
      productPlans={productPlans}
      product={product}
      price={price}
      offerPrice={offerPrice}
      loading={loading}
      isSubscription={hasSubscriptionPlan}
      isAddToBasketView={isAddToBasketView}
      onClick={() => onClick(trackingData)}
      height={height}
      onAddToBasketCallback={onAddToBasketCallback}
    />
  );
};

export default RecommendedProduct;
