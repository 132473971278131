import React from 'react';

import { IProduct } from '@features/shop/interfaces/shop';
import ProductBadge from '@features/shop/components/product/product-badge';
import ProductLink from './product-link';
import FooterWithLink from './footer-with-link';
import { IProductPlan } from '../../../types/product';
import AddToBasketDropdown from '../add-to-basket-dropdown/add-to-basket-dropdown';
import ProductImage from '@src/features/shop/components/product/product-image';
import styles from './recommended-product.module.scss';
import { IBasketItem } from '../../../types/basket';

interface IProps {
  productPlans?: IProductPlan[];
  product: IProduct;
  price: string;
  loading: boolean;
  isSubscription: boolean;
  isAddToBasketView?: boolean;
  offerPrice?: string;
  personalized?: boolean;
  height?: number;
  onClick: () => void;
  onAddToBasketCallback?: (currency: string, lastModifiedItem?: IBasketItem, sku?: string) => void;
}

const RecommendedProductUI = ({
  productPlans,
  product,
  price,
  offerPrice,
  loading,
  personalized = false,
  isAddToBasketView,
  height,
  onClick,
  onAddToBasketCallback,
}: IProps) => {
  return (
    <div className={styles.product} style={{ minHeight: height ? height : 'auto' }}>
      <ProductBadge product={product} className={styles.badgeWrap} />
      <ProductLink link={product.route} onClick={onClick}>
        <ProductImage
          filenameDisk={product.filename_disk}
          backgroundColor={product.background_color}
        />
      </ProductLink>
      <p className={styles.header}>{product.name}</p>
      <p className={styles.description}>{product.short_description || product.description}</p>

      {isAddToBasketView ? (
        <AddToBasketDropdown
          productPlans={productPlans}
          personalized={personalized}
          onSubmitCallback={onAddToBasketCallback}
        />
      ) : (
        <FooterWithLink
          product={product}
          price={price}
          offerPrice={offerPrice}
          loading={loading}
          personalized={personalized}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default RecommendedProductUI;
