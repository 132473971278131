import React, { useState } from 'react';
import { SwiperComponent } from 'vitl-component-library';
import { useMediaQuery } from 'react-responsive';

import { IProduct } from '@features/shop/interfaces/shop';
import RecommendedProduct, {
  IHomepageRecommendedProductTrack,
} from '../recommended-product/recommended-product';
import { trackEvent } from '@services/tracking/tracking';
import { IBasketItem } from '../../../types/basket';
import { getPriceForAddOrRemoveItem } from '@src/services/tracking/ga';

import styles from './recommended-products.module.scss';

interface IEvent {
  currency: string;
  value?: number;
  items?: IBasketItem[];
}

interface IProps {
  products: IProduct[];
  isAddToBasketView?: boolean;
  height?: number;
  onAddToBasketCallback?: (
    activeSlide: number,
    currency: string,
    lastModifiedItem?: IBasketItem,
    sku?: string
  ) => void;
}

const RecommendedProducts = ({
  products,
  isAddToBasketView,
  height,
  onAddToBasketCallback,
}: IProps) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const onClickHandler = (data: IHomepageRecommendedProductTrack) => {
    trackEvent('hp_productrec', data);
  };

  const onAddToBasket = (currency: string, lastModifiedItem?: IBasketItem, sku?: string) => {
    onAddToBasketCallback && onAddToBasketCallback(activeSlide, currency, lastModifiedItem, sku);
  };

  return (
    <div className={styles.container}>
      <SwiperComponent
        className={styles.crossSlider}
        onRealIndexChange={swiper => setActiveSlide(swiper.realIndex + 1)}
        paginationType="bullets"
        overlaps={!isDesktop}
        pagination={isDesktop}
        // showArrows={isDesktop}
        slideToClickedSlide={true}
        allowTouchMove={true}
        loop={!isDesktop}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        {products.map((product: IProduct, index: number) => (
          <RecommendedProduct
            product={product}
            index={index}
            onClick={onClickHandler}
            isAddToBasketView={isAddToBasketView}
            height={height}
            key={product.identifier}
            onAddToBasketCallback={onAddToBasket}
          />
        ))}
      </SwiperComponent>
    </div>
  );
};

export default RecommendedProducts;
