import React from 'react';

import { SUBSCRIPTION_PLANS } from '@constants/subscription-plans';
import { formatPrice } from '@services/format';
import { IProductPlan } from '../../../types/product';

import styles from './add-to-basket-dropdown.module.scss';

interface IProps {
  productPlans?: IProductPlan[];
  plan: IProductPlan;
  onClick?: () => void;
}

const DropdownItem = ({ productPlans, plan, onClick }: IProps) => {
  const isSubscription = () => !!plan?.deliveryFrequency;

  const getLabel = () => {
    if (isSubscription()) return 'Subscribe & save';
    return 'Buy once';
  };

  const getDiscount = () => {
    if (productPlans && plan && isSubscription()) {
      const price = productPlans[1].rrpPrice;
      const discountPrice = plan.rrpPrice / 100;
      const total = price - price * discountPrice;
      return <span className={styles.badge}>{Math.round(total)}% Off</span>;
    }
    return null;
  };

  return (
    <div className={styles.dropdownInner} onClick={onClick}>
      <div className={styles.left}>
        <span>{getLabel()}</span>
        <span className={styles.price}>{formatPrice(plan.rrpPrice, plan.currency)}</span>
      </div>
      <div>{getDiscount()}</div>
    </div>
  );
};

export default DropdownItem;
