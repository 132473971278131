import React from 'react';

import { IProduct } from '@features/shop/interfaces/shop';
import RecommendedProductUI from '../recommended-product-ui/recommended-product-ui';
import useGetEssentialProductPlan from '@features/shop/hooks/useGetEssentialProductPlan';
import { IBasketItem } from '../../../types/basket';

interface IProps {
  product: IProduct;
  isAddToBasketView?: boolean;
  height?: number;
  onClick: () => void;
  onAddToBasketCallback?: (currency: string, lastModifiedItem?: IBasketItem, sku?: string) => void;
}

const EssentialProduct = ({
  product,
  isAddToBasketView,
  height,
  onClick,
  onAddToBasketCallback,
}: IProps) => {
  const {
    price,
    plans,
    loadingPlans,
    loadingProducts,
    hasSubscriptionPlan,
  } = useGetEssentialProductPlan();

  return (
    <RecommendedProductUI
      personalized
      product={product}
      productPlans={plans ? plans : undefined}
      price={price}
      loading={loadingPlans || loadingProducts}
      isSubscription={hasSubscriptionPlan}
      isAddToBasketView={isAddToBasketView}
      onClick={onClick}
      height={height}
      onAddToBasketCallback={onAddToBasketCallback}
    />
  );
};

export default EssentialProduct;
