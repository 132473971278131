import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';

import { IProductPlan } from '../../../types/product';
import useClickedOutside from '@hooks/useClickedOutside';
import DropdownItem from './dropdown-item';
import { getCookie } from '@services/cookies';
import { COOKIES } from '@constants/cookies';
import { useAddToBasket } from '@features/shop/hooks/useAddToBasket';
import { GlobalContext } from '@store/global-state';
import SubmitButton from '@components/submit-button/submit-button';
import Image from '@components/image/image';
import { IBasketItem } from '../../../types/basket';

import styles from './add-to-basket-dropdown.module.scss';
import { OUT_OF_STOCK } from '@src/constants/messages';

interface IProps {
  productPlans?: IProductPlan[];
  personalized: boolean;
  onSubmitCallback?: (currency: string, lastModifiedItem?: IBasketItem, sku?: string) => void;
}

const AddToBasketDropdown = ({ productPlans, personalized, onSubmitCallback }: IProps) => {
  const [selected, setSelected] = useState<IProductPlan>();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const elementRef = useRef(null);
  const { setHasUserAddedProductToBasket, setShowBasket } = useContext(GlobalContext);

  const { addItemToBasket, loading: loadingCard } = useAddToBasket(onCompletedCallback);

  const outsideElementClicked = () => {
    if (dropdownIsOpen) {
      setDropdownIsOpen(false);
    }
  };

  useClickedOutside(elementRef, outsideElementClicked);

  useEffect(() => {
    if (productPlans) {
      setSelected(productPlans[0]);
    }
  }, [productPlans]);

  function onCompletedCallback() {
    setHasUserAddedProductToBasket(true);
    setShowBasket(true);
  }

  const submit = async (sku: string) => {
    const basketId = getCookie(COOKIES.basketId);
    const result = await addItemToBasket({
      variables: {
        basketId,
        sku,
        quantity: 1,
      },
    });
    if (result?.data?.basket_addItem) {
      const { currency, lastModifiedItem } = result.data.basket_addItem;
      const sku = lastModifiedItem.sku;
      onSubmitCallback && onSubmitCallback(currency, lastModifiedItem, sku);
    }
  };

  const handleSubmit = () => {
    if (selected) {
      submit(selected.sku);
    }
  };

  if (personalized && !selected)
    return (
      <Link className={styles.buttonPrimary} to="/consultation">
        Take Health Quiz
      </Link>
    );

  if (!selected)
    return (
      <div className={styles.backInStockSoon}>
        <div className={styles.backInStockSoonBtn}>{OUT_OF_STOCK}</div>
        <p className={styles.bottomText}>Free UK delivery for subscriptions</p>
      </div>
    );

  const multiplePlans = productPlans && productPlans.length > 1;

  return (
    <div className={styles.addToCardDropdown}>
      <div
        className={styles.footer}
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
        ref={elementRef}
      >
        <Image
          className={dropdownIsOpen ? styles.chevronFlipped : styles.chevron}
          src={'/images/icon_chevron.svg'}
          width={8}
        />
        {dropdownIsOpen && multiplePlans ? null : (
          <DropdownItem plan={selected} productPlans={productPlans} />
        )}
        <ul className={`${dropdownIsOpen ? styles.dropdownOpen : styles.dropdown}`}>
          {multiplePlans &&
            productPlans.map(plan => (
              <div className={styles.dropdownItem} key={plan.sku}>
                <DropdownItem
                  plan={plan}
                  productPlans={productPlans}
                  onClick={() => setSelected(plan)}
                />
              </div>
            ))}
        </ul>
      </div>

      <SubmitButton
        className={styles.button}
        onClick={handleSubmit}
        buttonLabel="Add to Basket"
        loading={loadingCard}
        fullWidth
      />

      <p className={styles.bottomText}>Free UK delivery for subscriptions</p>
    </div>
  );
};

export default AddToBasketDropdown;
