import React from 'react';
import { Link } from 'gatsby';

import styles from './recommended-product.module.scss';

interface IRoute {
  link: string;
  children: React.ReactNode;
  footer?: boolean;
  onClick: () => void;
}

const ProductLink = ({ link, footer, onClick, children }: IRoute) => {
  if (link)
    return (
      <Link to={`/${link}`} className={`${styles.link} ${footer ? styles.footerLink : ''}`} onClick={onClick}>
        {children}
      </Link>
    );

  return <div className={styles.link}>{children}</div>;
};

export default ProductLink;
