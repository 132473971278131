import { useEffect, useContext } from 'react';
import { GlobalContext } from '../store/global-state';

const useGlobalError = (error: boolean, errorMessage: string) => {
  const { setErrorModalMessage } = useContext(GlobalContext);
  useEffect(() => {
    if (error) return setErrorModalMessage(errorMessage);
  }, [error]);
};

export default useGlobalError;
